@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.changeDeviceOutputLink {
    &__desktop {
        margin-top: $global-spacing;
        display: flex;
        justify-content: center;

        .link {
            color: $sophieWhite;
        }
    }
}
