@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/mixins';

.gratificationBar {
    color: $blueStone;
    background: $whaleHelloThere;
    line-height: 16px;
    font-size: 11px;
    padding: 6px 15px;

    @media (width > 1280px) {
        padding: 6px 50px;
    }

    &__tablet {
        line-height: 17px;
    }

    &__desktopOnly {
        .contents {
            align-items: center;
        }
    }

    &__gold,
    &__level1,
    &__level2 {
        color: $tooLarsToHandle;
        background: $pancho;
    }

    &__gold-error,
    &__level1-error,
    &__level2-error {
        color: $ersterFussballClubKoeln;
        background: $pancho;
    }

    &__error {
        color: $ersterFussballClubKoeln;
    }

    &__withPointer {
        cursor: pointer;
    }
}

.contents {
    display: flex;

    &__limitedWidth {
        max-width: 1280px;
        margin: auto;
    }
}

.icon,
.questionMarkIcon {
    font-size: 16px;
    display: inline-flex;
    vertical-align: middle;
}

.questionMarkIcon {
    cursor: help;
}

.icon {
    margin-right: 10px;

    // To fix alignment with other icon.
    margin-top: 1px;
}

.text {
    font-weight: bold;
    margin-right: 10px;
}

.tooltipContent {
    padding: $global-spacing;
    max-width: 300px;

    // Overwrite margin on paragraphs used by API as HTML content.
    & p {
        margin-bottom: 0;
        line-height: 18px;
    }
}

.goldClubLogo {
    margin-left: auto;
    color: $tooLarsToHandle;
    font-size: 14px;
}
