@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.container {
    &__mobile {
        .csCode {
            font-size: 16px;
        }
    }
}

.badge {
    margin-bottom: $global-spacing-xsmall;
}
