@import 'Application/view/generated/colors';

$borderWidth: 1px;
$boxShadow: 0 1px 8px rgba($darkSideOfTheForce, 0.3);
$arrowSizeDefault: 21px;
$arrowSizeLarge: 33px;
$minCornerSpace: 3px;
$arrowContainerCloserSpacer: 15px;

// default theme
$backgroundDefault: $sophieWhite;
$borderColorDefault: transparent;
$colorDefault: $silverStar;

// dark theme
$backgroundDark: rgba($effiEffiSteffi, 0.9);
$borderColorDark: transparent;
$colorDark: $sophieWhite;

// error theme
$borderColorError: get-color(alert);

// green theme
$backgroundGreen: $lightGreen;
$borderColorGreen: transparent;

// grey theme
$borderColorGrey: $grey;

// black theme (used only for gold-club)
$backgroundBlack: rgba($darkSideOfTheForce, 0.9);
$borderColorBlack: transparent;
$colorBlack: $sophieWhite;

/**
 * Defines a .arrow and .arrowContainer class with basic styles for
 * a triangle-based arrow including position attributes for the usages
 * within tippy.js
 */
@mixin tooltipContainerArrow($arrowSize: 14px, $backgroundColor: $sophieWhite) {
    .arrow {
        position: absolute;
        width: $arrowSize;
        height: $arrowSize;
        background: $backgroundColor;
        transform: rotate(45deg);
        box-shadow: $boxShadow;
    }

    .arrowContainer {
        height: $arrowSize;
        width: 30px;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }

    // In order to apply the correct placement we need to check
    // where the container is rendered
    // offset by 1px to prevent the box-shadow from being visible between the arrow and the container
    .arrowContainer[data-placement^='top'] {
        top: calc(100% - 1px);

        .arrow {
            top: -50%;
        }
    }

    .arrowContainer[data-placement^='bottom'] {
        bottom: calc(100% - 1px);

        .arrow {
            bottom: -50%;
        }
    }

    .arrowContainer[data-placement^='left'] {
        left: calc(100% - 1px);
        height: 30px;
        width: $arrowSize;
        align-items: center;

        .arrow {
            left: -50%;
        }
    }

    .arrowContainer[data-placement^='right'] {
        right: calc(100% - 1px);
        height: 30px;
        width: $arrowSize;
        align-items: center;

        .arrow {
            right: -50%;
        }
    }
}
