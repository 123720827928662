@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.shadow {
    position: relative;

    &:after {
        content: '';
        height: 21px;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        background: linear-gradient(to bottom, rgba($sophieWhite, 0), rgba($sophieWhite, 1) 100%);
    }
}

.toggleButtonContainer {
    &__inline {
        display: inline-block;
        padding-top: 0;
    }

    &__alignRight {
        .toggleButton {
            justify-content: flex-end;
        }
    }
}

.toggleButton {
    display: flex;
    align-items: center;
}

.toggleArrow {
    line-height: 0;
    font-size: 24px;
}
