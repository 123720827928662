@import 'Application/view/generated/colors';

.statusText {
    margin-top: 8px;
    font-size: 12px;
    line-height: 16px;
    color: $sophieWhite;

    &__onWhiteBackground {
        color: unset;
    }

    &__failed {
        color: $ersterFussballClubKoeln;
    }
}
