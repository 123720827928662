@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.slideToggle {
    position: relative;
    width: 50px;
    height: 25px;
    background-color: $sophieWhite;
    border: 1px solid $plainGrey;
    border-radius: 50px;
    box-shadow: inset -20px 0 0 0 $sophieWhite;
    transition-duration: 200ms;
    margin-bottom: 0;

    &:hover {
        cursor: pointer;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 23px;
        height: 23px;
        background-color: transparent;
        border-radius: 50%;
        box-shadow: 2px 2px 6px rgba($darkSideOfTheForce, 0.2);
    }

    &__isChecked {
        border-color: $blueFire;
        box-shadow: inset 25px 0 0 0 $blueFire;

        &:after {
            left: 20px;
            box-shadow: -2px 4px 3px rgba($darkSideOfTheForce, 0.05);
        }
    }
}
