@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.childrenAgeFields {
    display: flex;
    flex-wrap: wrap;
    gap: $global-spacing-smedium $global-spacing-medium;
}

.field {
    flex: 0 1 calc(50% - #{$global-spacing-smedium});
}

.childrenAgeHint {
    font-size: 12px;

    &__hasError {
        color: $ersterFussballClubKoeln;
    }
}
