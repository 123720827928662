@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.errorPage {
    margin-top: 60px;
    margin-bottom: 60px;

    &__mobile {
        margin-top: 30px;
    }
}

.header {
    display: flex;
    align-items: center;
    gap: $global-spacing-small;
    font-size: 20px;
    margin-bottom: 30px;
}

.headerIcon {
    display: inline-flex;
    color: $red;
}

.errorMessage {
    font-size: 16px;
}
