@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Application/view/components/splashScreen/common/variables';

$spaceBetweenBoxes: $global-spacing;
$plusSignWidth: 25px;

.bonusContainer {
    column-gap: $spaceBetweenBoxes;
    position: relative;
    display: flex;
    width: $bonusBoxWidth * 2 + $spaceBetweenBoxes;

    &__mobile {
        width: $bonusBoxWidthMobile * 2 + $spaceBetweenBoxes;

        .plusSign {
            left: $bonusBoxWidthMobile - ($plusSignWidth - $spaceBetweenBoxes) * 0.5;
        }
    }

    &__tablet {
        .plusSign {
            // Is needed to center the plus icon on older ios versions
            margin-left: 0;
        }
    }
}

.plusSign {
    position: absolute;
    width: $plusSignWidth;
    left: $bonusBoxWidth - ($plusSignWidth - $spaceBetweenBoxes) * 0.5;
    font-size: $plusSignWidth;
    color: $sophieWhite;
    display: flex;
    align-items: center;
    height: 100%;

    > span {
        background: $vermilion;
        border-radius: 50%;
    }
}
