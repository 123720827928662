@import 'Application/view/generated/c24-sprite';

@mixin c24SpriteSlice($sprite, $scale) {
    &:before {
        @include c24-sprite-sprite($sprite);

        transform: scale($scale);
        transform-origin: top left;
        content: '';
        display: block;
    }

    width: nth($sprite, 5) * $scale;
    height: nth($sprite, 6) * $scale;
    overflow: hidden;
}
