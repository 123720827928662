$overlayHeaderHeight: 70px;
$overlayFooterHeight: 76px;
$overlayCloseIconSize: 25px;
$overlayCloseIconGap: 10px;
$spaceAboveOverlay: $overlayCloseIconSize + ($overlayCloseIconGap * 2);
$spaceBelowOverlay: 20px;
$maxContentHeight: 500px;
$maxContentWidth: 500px;
$minDesktopOverlayHeight: 550px;
$hintContainerHeight: 75px;
$smallScreenMinOverlayContentHeight: 60vh;
$smallScreenMaxOverlayContentHeight: 75vh;
$maxOverlayContentHeight: $maxContentHeight + $overlayHeaderHeight + $overlayFooterHeight;
$overlayContainerElementsHeight: $overlayHeaderHeight + $overlayFooterHeight + $spaceAboveOverlay + $spaceBelowOverlay;
$fixedDesktopOverlayHeight: 646px;
$fixedDesktopOverlayWidth: 1058px;
$fixedSmallScreenOverlayWidth: 835px;
