@mixin autoFillAnimations($prop: 'color', $value: $silverStar) {
    @keyframes onAutoFillStart {
        0% {
            #{$prop}: $value;
        }

        100% {
            #{$prop}: $value;
        }
    }

    @keyframes onAutoFillCancel {
        0% {
            #{$prop}: $value;
        }

        100% {
            #{$prop}: $value;
        }
    }
}
