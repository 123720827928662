@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/zIndices';

.background {
    position: fixed;
    z-index: zIndex(wireframeOverlays);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    // Assure the default left alignment for the text
    text-align: left;
    white-space: normal;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    overscroll-behavior: contain;

    // Prevents the popup from inheriting the cursor of the calling element
    cursor: default;

    &__reducedZIndex {
        z-index: 19995;
    }

    &__increasedZIndex {
        z-index: zIndex(c24wireframe);
    }

    &__overlayPosition {
        &_top,
        &_top-right,
        &_top-left,
        &_right-top,
        &_left-top {
            justify-content: flex-start;
        }

        &_bottom,
        &_bottom-right,
        &_bottom-left,
        &_right-bottom,
        &_left-bottom {
            justify-content: flex-end;
        }

        &_left,
        &_left-top,
        &_left-bottom,
        &_top-left,
        &_bottom-left {
            align-items: flex-start;
        }

        &_right,
        &_right-top,
        &_right-bottom,
        &_top-right,
        &_bottom-right {
            align-items: flex-end;
        }
    }
}
