@import 'Application/view/framework/common/settings';

.container {
    display: flex;
    align-items: center;
    padding: 4px 0;
    gap: $global-spacing-small;
}

.checkIcon {
    display: flex;
    align-items: center;
    font-size: 16px;
}
