@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/generated/assets/assets';
@import 'Application/view/framework/common/mixins/mixins';
@import 'Icons/view/generated/icons';
@import 'Application/view/framework/common/button';

body {
    background: $alabaster;
}

$componentClass: errorPage;

.#{$componentClass} {
    &__container {
        border-radius: 3px;
        overflow: hidden;
    }

    &__teaser {
        background: url($_404TeaserJpg) no-repeat center;
        background-size: cover;

        .webp & {
            background: url($_404TeaserWebp) no-repeat;
        }
    }

    &__content {
        padding: 40px 60px;
    }

    &__headline,
    &__subHeadline {
        color: $silverStar;
        font-weight: bold;
    }

    &__headline {
        line-height: 1;
    }

    &__subHeadline {
        font-size: 14px;
        margin-bottom: 20px;
    }

    &__message {
        font-size: 14px;
        margin-bottom: 40px;
    }

    &--desktop {
        margin: 0 0 50px;

        .#{$componentClass} {
            &__container {
                display: flex;
                align-items: stretch;
                background: $sophieWhite;
                box-shadow: 0 0 3px rgba($darkSideOfTheForce, 0.2);
            }

            &__teaser {
                flex: 50%;
            }

            &__content {
                flex: 50%;
            }

            &__ctaContainer {
                text-align: right;
                margin-bottom: 70px;
            }

            &__contactDetails {
                display: flex;
                column-gap: $global-spacing-large;
            }

            &__contactDetail {
                flex: 50%;
                display: flex;
                column-gap: $global-spacing;
            }
        }
    }

    &--mobile {
        background: url($_404TeaserJpg) no-repeat center;
        background-size: cover;

        .#{$componentClass} {
            &__content {
                padding: $global-spacing-large;
            }

            &__headline {
                font-size: 40px;
                color: $silverStar;
            }

            &__subHeadline {
                font-size: 16px;
                margin-bottom: 50px;
                color: $silverStar;
            }

            &__message {
                font-size: 16px;
            }
        }

        .frontpageTitle {
            color: $silverStar;
        }
    }

    &__noticeHeadline {
        font-size: 14px;
    }

    &__noticeSubHeadline {
        color: $dustyGrey;
    }

    &__phoneNumber:hover,
    &__email:hover {
        text-decoration: underline;
    }

    &__icon {
        @include iconFont;

        font-size: 24px;
        color: $blueFire;
    }

    &__mail {
        font-size: 33px;
        margin-top: -5px;
    }
}

.contact {
    display: block;
    position: relative;
    padding: 15px 0;

    &__row {
        position: relative;
        padding-left: 40px;

        &:before {
            @include iconFont;

            position: absolute;
            content: $phoneIcon;
            font-size: 32px;
            left: 0;
            color: $blueFire;
            transform: translateY(-50%);
            top: 50%;
        }
    }

    &__text {
        margin-bottom: 0;
        color: $dustyGrey;
        font-size: 14px;
        line-height: 16px;
    }
}

.frontpageTitle {
    font-size: 16px;
    line-height: 1.25;
    color: $silverStar;
}

.frontpageBox {
    position: relative;
    border: 1px solid $marktMaedels;
    font-size: 14px;
    display: block;
    background: $sophieWhite;

    &--linkArrow {
        padding: 0 $global-spacing-large;

        &:before {
            @include iconFont;

            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 32px;
            line-height: 24px;
            color: $grey;
            content: $arrowContourRightIcon;
        }
    }
}

.errorPageBreadcrumb {
    margin: 23px 0;

    &__link {
        font-size: 12px;
        display: inline-block;

        &:hover {
            color: $juna;
            text-decoration: underline;
        }
    }

    &__icon {
        @include iconFont;

        transform: translateY(22%);
        font-size: 20px;
        padding: 0 8px;
        display: inline-block;
        line-height: 1;
        color: $silverStar;

        &:before {
            content: $arrowContourRightIcon;
        }
    }
}
