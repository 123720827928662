@mixin small-phone {
    @media screen and ((width <= 360px) or (height <= 700px)) {
        @content;
    }
}

.focusImageContainer {
    position: absolute;
    top: 190px;
    left: 195px;

    @include small-phone {
        transform: scale(0.75);
        top: 23%;
        left: 60%;
    }
}
