@import 'Application/view/framework/common/variables.tabletApp';

// Adjust width and center elements in tabletApp webview.
@mixin tabletAppMaxWidth {
    max-width: $tabletAppMaxWidth;

    @media screen and (width > $tabletAppMaxWidth) {
        margin: 0 auto;
    }
}
