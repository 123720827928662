@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/safeAreaInset';
@import 'Application/view/generated/zIndices';

.fixedWrapper {
    position: fixed;
    box-shadow: 0 0 10px rgba($darkSideOfTheForce, 0.3);
    z-index: zIndex(c24wireframe) + 1;

    @include safeAreaInset(bottom, left, right);
}

.offlineHint {
    padding: 10px;
    background: $effiEffiSteffi;
    color: $sophieWhite;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    width: 100%;
    justify-content: center;
    column-gap: $global-spacing-smedium;

    &__onlineAgain {
        background: $chatbotBaumGreen;
    }
}
