@import 'Application/view/framework/common/settings';

.actionLinks {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
}

.errorMessage {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: $global-spacing-xsmall;
}
