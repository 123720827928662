@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.csCodeBadge {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 7px;
    color: $silverStar;
    line-height: 1;
    flex: 1 1 auto;
    height: 26px;
    border: 1px solid $silverStar;
    border-radius: $global-radius;
    padding: 0 7px;

    &__forGoldClubMember {
        color: $goldieMcGoldface;
        border-color: $goldieMcGoldface;
    }

    &__withWhiteBackground {
        background-color: $sophieWhite;
    }

    &__withoutVerb {
        column-gap: 4px;

        .price {
            font-weight: normal;
        }

        .label {
            font-size: 15px;
            line-height: 1.3;
        }
    }
}

.tooltipBadgeWrapper {
    cursor: help;
}

.price {
    flex: 0 1 auto;
    font-size: 15px;
    line-height: 1.3;
    font-weight: bold;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.label {
    font-size: 8px;
    line-height: 1.3;
    text-align: left;
}

.tooltipContent {
    text-align: left;
    position: relative;
    justify-content: flex-end;
    display: flex;
    flex: 1 1 auto;
}

.greenBadge {
    font-size: 14px;
    display: flex;
    background-color: $chatbotBaumGreen;
    color: $sophieWhite;
    border-radius: $global-radius;
    padding: 2px 4px;
    flex-grow: 0;

    &__desktop {
        font-size: 12px;
    }

    &__tablet {
        text-align: center;
    }

    &__greenMultiLine {
        text-align: right;
        white-space: nowrap;
    }
}
