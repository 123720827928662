@import 'Application/view/framework/common/settings';

.childAgeBottomSheet {
    padding: $global-spacing-medium $global-spacing 0;
}

.headline {
    padding-bottom: $global-spacing-small;
    font-weight: bold;
    font-size: 18px;
}

.options {
    max-height: 50vh;
    overflow: hidden auto;
    padding-bottom: $global-spacing-small;
}

.option {
    font-size: 16px;
}
