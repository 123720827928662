@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.wireframeContactTooltipDesktop {
    width: 280px;

    * {
        box-sizing: border-box !important;
    }
}
