@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

$componentClass: button;

.#{$componentClass} {
    display: inline-block;
    vertical-align: middle;
    padding: 0.85em 1em;
    border: 1px solid transparent;
    border-radius: 3px;
    transition:
        background-color 0.25s ease-out,
        color 0.25s ease-out,
        border 0.25s ease-out;
    font-family: $body-alternative-font-family;
    font-size: 14px;
    appearance: none;
    line-height: 1;
    text-align: center;
    cursor: pointer;

    &--mobile,
    &--app {
        font-size: 20px;
        padding: 14px 10px;
    }

    &--style-default {
        &,
        &:hover,
        &:focus {
            color: $sophieWhite;
            background-color: $werkiWilkeBlue;
            border-color: transparent;
        }

        &.button--desktop:hover {
            background-color: $endeavour;
        }
    }

    &--style-secondary {
        &,
        &:hover,
        &:focus {
            color: $doveGrey;
            background-color: $sophieWhite;
            border: 1px solid $grey;
        }

        &.button--desktop:hover {
            background-color: $alabaster;
        }
    }

    &--expanded {
        width: 100%;
    }

    &--hideForPrint {
        @media print {
            display: none;
        }
    }
}
