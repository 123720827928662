@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/tooltip/variables';
@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/zIndices';

.tooltip {
    z-index: zIndex(tooltipsOverlay);
}

.trigger {
    display: inline-flex;
}

.container {
    display: flex;
    position: relative;
    align-items: flex-start;

    &__arrowDirectionLeft,
    &__arrowDirectionRight {
        flex-direction: row;
        margin: 5px 0;
    }

    &__arrowDirectionTop,
    &__arrowDirectionBottom {
        flex-direction: column;
        margin: 0 5px;
    }

    &__noMargin {
        margin: 0;
    }

    &__arrowAlignmentMiddle {
        align-items: center;
    }

    &__arrowAlignmentEnd {
        align-items: flex-end;
    }

    &__colorThemeWhite {
        &.container__mobile {
            .content {
                border-color: $blueFire;
                box-shadow: 0 1px 8px rgba($darkSideOfTheForce, 0.1);
            }
        }
    }

    &__colorThemeDarkBlue {
        .content {
            background: rgba($effiEffiSteffi, 0.9);
            border-color: rgba($effiEffiSteffi, 0);
            color: $sophieWhite;

            // direct children without styling
            & > a:not([class]),
                // unstyled children of unstyled elements
            & > *:not([class]) > a:not([class]),
                // unstyled children of any unstyled text elements
            p:not([class]) a:not([class]),
            span:not([class]) a:not([class]),
                // Api sends us HTML content with links with className="onclickhandler"
            :global(.onclickhandler) {
                color: currentColor;
                text-decoration: underline;
                font-weight: normal;

                &:hover {
                    color: $juna;
                    text-decoration: underline;
                }
            }
        }
    }

    &__colorThemeError {
        .content {
            border-color: $borderColorError;
            font-size: 11px;
        }
    }

    &__colorThemeTransparent {
        .content {
            border: none;
            background-color: transparent;
            box-shadow: none;
        }
    }

    &__paddingToCloseButton {
        .content:has(.closer) {
            .contentWrapper {
                padding-right: $global-spacing;
            }
        }
    }
}

.content {
    box-shadow: $boxShadow;
    font-size: 12px;
    line-height: $global-line-height;
    background: $sophieWhite;
    border: 1px solid $sophieWhite;
    border-radius: 5px;
    min-width: 50px;
    position: relative;

    .closer {
        position: absolute;
        font-size: 14px;
        right: 0;
        padding: 10px;
        color: $plainGrey;
        cursor: pointer;
        z-index: zIndex(overlays);
    }
}
