@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/generated/zIndices';
@import 'Application/view/framework/common/mixins/quadrat';

@keyframes spinnerRotation {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@mixin spinnerSize($size, $setMargin: true) {
    @include quadrat($size);

    @if $setMargin {
        margin: $size * -0.5;
    }
}

.spinner {
    position: absolute;
    display: none;
    content: '';
    border: solid transparentize($darkSideOfTheForce, 0.7);
    border-bottom-color: $blueFire;
    border-radius: 100%;

    // The delay gives IE enough time to properly align the spinner in advance.
    animation: 700ms linear 3ms infinite spinnerRotation;
    transform-origin: 50% 50%;

    &__tiny {
        display: block;

        @include spinnerSize(12px, false);
    }

    &__small {
        display: block;

        @include spinnerSize(17px, false);
    }

    &__default {
        @include spinnerSize(20px);
    }

    &__medium {
        display: block;

        @include spinnerSize(40px, false);
    }

    &__large {
        @include spinnerSize(80px);

        display: block;
        z-index: zIndex(contentElements);

        &:not(.spinner__hasDimensions) {
            top: 50%;
            left: 50%;
        }
    }

    &__auto {
        display: block;

        @include spinnerSize(100%, false);
    }

    &__tinyBorder {
        border-width: 2px;
    }

    &__smallBorder {
        border-width: 3px;
    }

    &__defaultBorder {
        border-width: 4px;
    }

    &__mediumBorder {
        border-width: 6px;
    }

    &__largeBorder {
        border-width: 10px;
    }

    &__hasDimensions {
        margin: 0;
        position: relative;
    }
}
