@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.toggleContainer {
    display: flex;
    min-width: 290px;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    margin-top: $global-spacing-small;
    padding-top: $global-spacing-smedium;
    border-top: 1px solid $marktMaedels;

    &__mobile {
        border-top: unset;
        padding-top: unset;
        margin-top: $global-spacing-smedium;
    }
}
