@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.childAgeInputButton {
    position: relative;
}

.inputArrow {
    color: $dustyGrey;
    font-size: 15px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: $global-spacing;
    line-height: 0;
}

.clickCatcher {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
