/**
 * This file is auto generated during scripts/generate-icons.ts.
 * Don't edit it manually.
 */

@import 'Application/view/generated/colors';
@import 'Icons/view/components/SvgTwigIcon.module';

$iconFontFamily: 'check24hotel' !default;
$iconFontUrl: 'Icons/view/generated/fonts' !default;

$addCardIcon: '\e000';
$additionalBabyBedIcon: '\e001';
$additionalBedOutlineIcon: '\e002';
$airConditioningIcon: '\e003';
$airportIcon: '\e004';
$alarmIcon: '\e005';
$arrowBottomIcon: '\e006';
$arrowContourBottomIcon: '\e007';
$arrowContourLeftIcon: '\e008';
$arrowContourRightIcon: '\e009';
$arrowContourTopIcon: '\e00a';
$assignmentIcon: '\e00b';
$atIcon: '\e00c';
$attentionIcon: '\e00d';
$babybedIcon: '\e00e';
$backArrowIcon: '\e00f';
$balconyIcon: '\e010';
$barbecueIcon: '\e011';
$bathIcon: '\e012';
$bathFullIcon: '\e013';
$bathroomIcon: '\e014';
$beachIcon: '\e015';
$bedOutlineIcon: '\e016';
$bestPriceGuaranteeIcon: '\e017';
$bikeIcon: '\e018';
$bikeRentalIcon: '\e019';
$businessIcon: '\e01a';
$businessCaseIcon: '\e01b';
$businessCaseOutlineIcon: '\e01c';
$businessCaseSlimIcon: '\e01d';
$calendarIcon: '\e01e';
$calendarBusyIcon: '\e01f';
$calendarEuroIcon: '\e020';
$calendarOpenIcon: '\e021';
$calendarRoundBordersIcon: '\e022';
$calendarSlimIcon: '\e023';
$camperOutlineIcon: '\e024';
$carSolidIcon: '\e025';
$castleIcon: '\e026';
$centerIcon: '\e027';
$chatIcon: '\e028';
$chatBubbleIcon: '\e029';
$checkIcon: '\e02a';
$check24TagIcon: '\e02b';
$checkCircleIcon: '\e02c';
$checkInIcon: '\e02d';
$checkIn24hIcon: '\e02e';
$checkOutIcon: '\e02f';
$chevronArrowDropdownIcon: '\e030';
$childIcon: '\e031';
$childOutlineIcon: '\e032';
$childrenFriendlyIcon: '\e033';
$childrensPlaygroundIcon: '\e034';
$clockIcon: '\e035';
$closeIcon: '\e036';
$closeCircleIcon: '\e037';
$closeSlimIcon: '\e038';
$cocktailGlassIcon: '\e039';
$coffeeIcon: '\e03a';
$coffeeOutlineIcon: '\e03b';
$coinsIcon: '\e03c';
$coinsEuroIcon: '\e03d';
$communityOutlineIcon: '\e03e';
$compareOppositeIcon: '\e03f';
$computerIcon: '\e040';
$conversationIcon: '\e041';
$copyIcon: '\e042';
$coronaShieldIcon: '\e043';
$coupleIcon: '\e044';
$coupleOutlineIcon: '\e045';
$coupleOutlineSlimIcon: '\e046';
$coupleWithChildOutlineIcon: '\e047';
$coupleWithChildSlimIcon: '\e048';
$crosshairIcon: '\e049';
$cutleryIcon: '\e04a';
$cutlerySlimIcon: '\e04b';
$cvcAmexIcon: '\e04c';
$cvcMasterVisaIcon: '\e04d';
$cyclingPreviewIcon: '\e04e';
$dangerSignIcon: '\e04f';
$dateArrowIcon: '\e050';
$directDebitIcon: '\e051';
$dishwasherIcon: '\e052';
$divingPreviewIcon: '\e053';
$docCircleIcon: '\e054';
$documentIcon: '\e055';
$documentCancelIcon: '\e056';
$documentDetailsIcon: '\e057';
$documentEditIcon: '\e058';
$documentFolderIcon: '\e059';
$documentsIcon: '\e05a';
$documentSearchIcon: '\e05b';
$donationIcon: '\e05c';
$downloadIcon: '\e05d';
$downloadSlimIcon: '\e05e';
$dryerIcon: '\e05f';
$elevatorIcon: '\e060';
$eMailIcon: '\e061';
$eMailContactsIcon: '\e062';
$emailMarkIcon: '\e063';
$eMailOutlineIcon: '\e064';
$eMailOutlineSlimIcon: '\e065';
$emptyNumberCircle1Icon: '\e066';
$emptyNumberCircle2Icon: '\e067';
$emptyNumberCircle3Icon: '\e068';
$euroIcon: '\e069';
$exclamationIcon: '\e06a';
$exclamationMarkCircleIcon: '\e06b';
$eyeIcon: '\e06c';
$facebookIcon: '\e06d';
$familyIcon: '\e06e';
$fatArrowRightIcon: '\e06f';
$fenceCenterIcon: '\e070';
$fileIcon: '\e071';
$fileImportIcon: '\e072';
$filledNumberCircle1Icon: '\e073';
$filledNumberCircle2Icon: '\e074';
$filledNumberCircle3Icon: '\e075';
$filterIcon: '\e076';
$fireplaceIcon: '\e077';
$fiveStarsIcon: '\e078';
$flagIcon: '\e079';
$flightOutlineIcon: '\e07a';
$forbiddenIcon: '\e07b';
$forwardArrowIcon: '\e07c';
$freeCancelationIcon: '\e07d';
$frowningFaceIcon: '\e07e';
$gardenIcon: '\e07f';
$golfPreviewIcon: '\e080';
$goodwillCancelationIcon: '\e081';
$grinningFaceIcon: '\e082';
$grinningSquintingFaceIcon: '\e083';
$groupIcon: '\e084';
$groupFullIcon: '\e085';
$groupOutlineIcon: '\e086';
$groupSlimIcon: '\e087';
$gymIcon: '\e088';
$handbagIcon: '\e089';
$handicappedFriendlyIcon: '\e08a';
$heartEmptyIcon: '\e08b';
$heartFullIcon: '\e08c';
$heatingIcon: '\e08d';
$hikingIcon: '\e08e';
$hikingPreviewIcon: '\e08f';
$holidayHomeIcon: '\e090';
$hollowHeartEmptyIcon: '\e091';
$hollowHeartFilledIcon: '\e092';
$homeEmptyIcon: '\e093';
$hotelIcon: '\e094';
$hotelBarIcon: '\e095';
$hotelsOwnBeachIcon: '\e096';
$hotlineIcon: '\e097';
$hourglassIcon: '\e098';
$indoorPoolIcon: '\e099';
$infoIcon: '\e09a';
$infoCircleIcon: '\e09b';
$invertedCheckCircleIcon: '\e09c';
$invertedExclamationIcon: '\e09d';
$invertedGiftIcon: '\e09e';
$invertedInfoIcon: '\e09f';
$invertedMinusCircleIcon: '\e0a0';
$invertedPlusCircleIcon: '\e0a1';
$invertedPointsIcon: '\e0a2';
$invertedQuestionMarkIcon: '\e0a3';
$jacuzziIcon: '\e0a4';
$kitchenIcon: '\e0a5';
$kitesurfingPreviewIcon: '\e0a6';
$lakeIcon: '\e0a7';
$languagesIcon: '\e0a8';
$layersIcon: '\e0a9';
$lightBulbIcon: '\e0aa';
$linkIcon: '\e0ab';
$listCheckIcon: '\e0ac';
$listCircleIcon: '\e0ad';
$livingComfortIcon: '\e0ae';
$localAtmIcon: '\e0af';
$lockIcon: '\e0b0';
$loginIcon: '\e0b1';
$luggageIcon: '\e0b2';
$mailIcon: '\e0b3';
$mailAtIcon: '\e0b4';
$manageSearchIcon: '\e0b5';
$mapFoldedOutlineIcon: '\e0b6';
$mapMarkerIcon: '\e0b7';
$mapMarkerFilledIcon: '\e0b8';
$mapMarkerInvertedIcon: '\e0b9';
$mapMarkerInvertedSlimIcon: '\e0ba';
$mapWithMarkerIcon: '\e0bb';
$medalIcon: '\e0bc';
$messageBubblesIcon: '\e0bd';
$messengerIcon: '\e0be';
$microwaveIcon: '\e0bf';
$minibarIcon: '\e0c0';
$minusIcon: '\e0c1';
$moreIcon: '\e0c2';
$morePicturesIcon: '\e0c3';
$mountainsIcon: '\e0c4';
$myLocationIcon: '\e0c5';
$natureIcon: '\e0c6';
$neutralFaceIcon: '\e0c7';
$nightClubIcon: '\e0c8';
$noCoffeeIcon: '\e0c9';
$noConvenienceFeeIcon: '\e0ca';
$nonSmokerRoomIcon: '\e0cb';
$notAvailableIcon: '\e0cc';
$paperClipIcon: '\e0cd';
$parkingIcon: '\e0ce';
$parkingSlimIcon: '\e0cf';
$paymentCreditCardIcon: '\e0d0';
$payoutIcon: '\e0d1';
$pdfIcon: '\e0d2';
$penIcon: '\e0d3';
$penSlimIcon: '\e0d4';
$percentageIcon: '\e0d5';
$percentageInvertedIcon: '\e0d6';
$personSearchIcon: '\e0d7';
$petsAllowedIcon: '\e0d8';
$petsAllowedOutlineIcon: '\e0d9';
$petsAllowedSlimIcon: '\e0da';
$phoneIcon: '\e0db';
$phoneOutlineIcon: '\e0dc';
$phoneOutlineSlimIcon: '\e0dd';
$phoneWithCircleIcon: '\e0de';
$plusIcon: '\e0df';
$plusCircleIcon: '\e0e0';
$poiIcon: '\e0e1';
$pointsGiftIcon: '\e0e2';
$poolIcon: '\e0e3';
$poutingFaceIcon: '\e0e4';
$priceChangeIcon: '\e0e5';
$priceTagOutlineIcon: '\e0e6';
$printIcon: '\e0e7';
$priorityBoostIcon: '\e0e8';
$publishWithChangesIcon: '\e0e9';
$questionMarkIcon: '\e0ea';
$radioButtonOffIcon: '\e0eb';
$radioButtonOnIcon: '\e0ec';
$receiptIcon: '\e0ed';
$redeemPointsIcon: '\e0ee';
$reloadIcon: '\e0ef';
$reloadSlimIcon: '\e0f0';
$resizeIcon: '\e0f1';
$restaurantIcon: '\e0f2';
$roofDeckIcon: '\e0f3';
$roomIcon: '\e0f4';
$roomSizeIcon: '\e0f5';
$rotationIcon: '\e0f6';
$safeIcon: '\e0f7';
$safeOutlineIcon: '\e0f8';
$safeOutlineSlimIcon: '\e0f9';
$saunaIcon: '\e0fa';
$savingsIcon: '\e0fb';
$searchIcon: '\e0fc';
$securityLockIcon: '\e0fd';
$sendIcon: '\e0fe';
$sendArrowIcon: '\e0ff';
$serviceIcon: '\e100';
$shareIcon: '\e101';
$showerIcon: '\e102';
$sightseeingIcon: '\e103';
$skiIcon: '\e104';
$skiingPreviewIcon: '\e105';
$sliderArrowBoldLeftIcon: '\e106';
$sliderArrowBoldRightIcon: '\e107';
$sliderArrowBottomIcon: '\e108';
$sliderArrowLeftIcon: '\e109';
$sliderArrowRightIcon: '\e10a';
$smartphoneIcon: '\e10b';
$smartphoneWithCheck24AppIcon: '\e10c';
$smilingFaceIcon: '\e10d';
$soloIcon: '\e10e';
$soloOutlineIcon: '\e10f';
$soloSlimIcon: '\e110';
$soloStandingIcon: '\e111';
$sortIcon: '\e112';
$sparklesIcon: '\e113';
$starIcon: '\e114';
$surfingPreviewIcon: '\e115';
$tableChairIcon: '\e116';
$tabletIcon: '\e117';
$teaIcon: '\e118';
$tenFor30InvertedIcon: '\e119';
$tennisIcon: '\e11a';
$thumbsDownIcon: '\e11b';
$thumbsUpIcon: '\e11c';
$thumbsUpRegularIcon: '\e11d';
$thumbsUpSolidIcon: '\e11e';
$toolbarFilterIcon: '\e11f';
$touristAttractionIcon: '\e120';
$trainOutlineIcon: '\e121';
$trainStationIcon: '\e122';
$transitIcon: '\e123';
$trashCanIcon: '\e124';
$trashCanSlimIcon: '\e125';
$travelToggleCruiseOutlineIcon: '\e126';
$travelToggleFlightAccommodationOutlineIcon: '\e127';
$travelToggleFlightOutlineIcon: '\e128';
$travelToggleHomesOutlineIcon: '\e129';
$travelToggleHotelOutlineIcon: '\e12a';
$travelToggleIndividualTravelOutlineIcon: '\e12b';
$travelTogglePackageVacationOutlineIcon: '\e12c';
$travelToggleRentalCarOutlineIcon: '\e12d';
$travelWorldIcon: '\e12e';
$trendingIcon: '\e12f';
$tvIcon: '\e130';
$twinBedIcon: '\e131';
$twinBedOutlineIcon: '\e132';
$uploadIcon: '\e133';
$userLoginIcon: '\e134';
$veganIcon: '\e135';
$viewIcon: '\e136';
$walkingIcon: '\e137';
$washingMachineIcon: '\e138';
$watersportsIcon: '\e139';
$wellnessIcon: '\e13a';
$whatsappIcon: '\e13b';
$wifiIcon: '\e13c';
$windsurfingPreviewIcon: '\e13d';
$wingfoilingPreviewIcon: '\e13e';
$wintersportsIcon: '\e13f';
$wrenchIcon: '\e140';
$writeIcon: '\e141';

@mixin iconFont($important: true) {
    font-family: $iconFontFamily if($important, !important, null);
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: 'liga';
    -moz-font-feature-settings: 'liga=1';
    -moz-font-feature-settings: 'liga';
    -ms-font-feature-settings: 'liga' 1;
    font-feature-settings: 'liga';
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin iconFontFace($urlPath: $iconFontUrl) {
    @font-face {
        font-family: $iconFontFamily;
        src: url('#{$urlPath}/check24hotel.woff2') format('woff2'),
             url('#{$urlPath}/check24hotel.woff') format('woff'),
             url('#{$urlPath}/check24hotel.ttf') format('truetype'),
             url('#{$urlPath}/check24hotel.svg') format('svg');
        font-weight: normal;
        font-style: normal;
        font-display: block; // prevent showing hexCodes while loading font but maybe lowers performance
    }
}

@mixin legacyIconFont() {
    position: relative;

    &:before {
        @include iconFont;
        position: absolute;
    }
}

/**
 * In favor of the transition to svg icon components
 * we define the scss based icons as legacy from now on
 */
@mixin legacyIconClasses() {

    .icon {
        @include iconFont;
    }

    @include svgTwigIcon;

    .icon-arrow-contour-left {
        @include legacyIconFont;

        &:before {
            content: $arrowContourLeftIcon;
            font-size: rem-calc(32px);
            line-height: 24px;
            left: 0;
        }
    }

    .icon-checked-before {
        @include legacyIconFont;
        &:before {
            content: $checkIcon;
            left: 0;
            color: $brat;
            font-size: rem-calc(16px);
        }

        &--small {
            &:before {
                font-size: rem-calc(12px);
            }

        }
    }

    .icon-alert-before {
        @include legacyIconFont;
        padding-left: 1.7rem;

        &:before {
            content: $exclamationIcon;
            left: 0;
            color: $ersterFussballClubKoeln;
            font-size: 1.3rem;
        }
    }

    .icon-hourglass-before {
        @include legacyIconFont;
        padding-left: 1.7rem;

        &:before {
            content: $hourglassIcon;
            left: 0;
            font-size: 1.3rem;
        }
    }

    .icon-phone-before {
        @include legacyIconFont;
        padding-left: 1.8rem;

        &:before {
            content: $phoneIcon;
            font-size: 1.8rem;
            left: 0;
        }
    }

    .icon-eMail-before {
        @include legacyIconFont;
        padding-left: 1.8rem;

        &:before {
            content: $eMailIcon;
            font-size: 1.5rem;
            line-height: 1;
            left: 0;
        }
    }

    .icon-bestPriceGuarantee-before {
        @include legacyIconFont;
        padding-left: 0;
        padding-right: 0;

        &:before {
            content: $bestPriceGuaranteeIcon;
            font-size: 1.5rem;
            line-height: 1;
            left: 10px;
        }
    }

    .icon-lock-before {
        @include legacyIconFont;

        &:before {
            content: $lockIcon;
            font-size: 1.5rem;
            line-height: 1;
            left: 10px;
        }
    }

    .icon-phoneWithCircle-before {
        @include legacyIconFont;

        &:before {
            content: $phoneWithCircleIcon;
        }
    }

    .icon-check24Tag-before {
        @include legacyIconFont;

        &:before {
            content: $check24TagIcon;
        }
    }

    .icon-percentage-before {
        @include legacyIconFont;

        &:before {
            content: $percentageIcon;
        }
    }

    .icon-noConvenienceFee-before {
        @include legacyIconFont;

        &:before {
            content: $noConvenienceFeeIcon;
        }
    }

    .icon-lightBulb-before {
        @include legacyIconFont;
        padding-left: 1.7rem;

        &:before {
            content: $lightBulbIcon;
            left: 0;
            font-size: 1.7rem;
            line-height: 1.3;
        }
    }
}
