@import 'Application/view/generated/colors';
@import 'Application/view/generated/assets/assets';

.plectrum {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 120px;
    height: 95px;
    text-align: center;
    color: $sophieWhite;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url($plectrumSvg);
}
