@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.option {
    cursor: pointer;
    padding: 0 $global-spacing-small;

    // Aware that this value is used in FloatingLabeledSelect to determine menu height.
    min-height: 21px;

    &__disabled {
        cursor: unset;
    }

    + .option {
        padding-top: 0;
    }

    &__selected:not(.option__disabled) {
        background-color: $almostWhite;
    }

    &:hover:not(.option__disabled),
    &__highlighted:not(.option__disabled) {
        background-color: $frosteon;
    }
}
