@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/textOverflowEllipsis';

.floatingLabel {
    @include textOverflowEllipsis;

    color: $dustyGrey;
    position: absolute;
    top: 0;
    left: $global-spacing;
    right: $global-spacing;
    z-index: 1;
    font-size: 15px;
    line-height: 1.5;
    pointer-events: none;
    transition:
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        border-color 0.25s ease-in-out;
    transform: translate(0, 14px) scale(1);
    transform-origin: top left;
    border: 1px solid transparent;

    &__withLessLeftSpacing {
        left: 9px;
    }

    &__inheritFontSize {
        font-size: inherit;
    }

    &__isFloating {
        transform: translate(1px, 5px) scale(0.75);
    }

    &__isActive {
        color: $blueFire;
    }

    &__withoutPadding {
        left: 0;
        right: 0;
    }

    &__darkLabel {
        color: $silverStar;
    }

    &__mobile {
        font-size: 18px;

        &.floatingLabel__isActive,
        &.floatingLabel__isFloating {
            font-size: 15px;
            line-height: 17px;
        }

        &.floatingLabel__isActive {
            color: $blueFire;
        }

        &.floatingLabel__isFloating {
            transform: translate(1px, 8px) scale(0.75);
        }

        &.floatingLabel__withError {
            color: $ersterFussballClubKoeln;
        }

        &.floatingLabel__withPaddingTop {
            // Is needed, to center the sps input field label vertically
            padding-top: 2px;
        }
    }
}
