@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/mixins';
@import 'Application/view/framework/common/mixins/safeAreaInset';

.bottomSheetLayout {
    transition: padding-bottom 200ms ease;

    &__none {
        --bottom-sheet-padding-bottom: 0;
    }

    &__small {
        --bottom-sheet-padding-bottom: #{$global-spacing-small};
    }

    &__default {
        --bottom-sheet-padding-bottom: #{$global-spacing};
    }

    &__app {
        &.bottomSheetLayout__withSafeArea {
            @include safeAreaInset(padding-bottom);
        }
    }
}

.headlineContainer {
    display: flex;
    align-items: center;
}

.headline {
    font-size: 20px;
    font-weight: bold;
    margin: 0;

    &__small {
        padding: $global-spacing-medium 10px $global-spacing-smedium;
    }

    &__default {
        padding: $global-spacing-medium $global-spacing $global-spacing-smedium;
    }
}

.headlineButton {
    font-size: 14px;
    margin: 14px 10px 0 auto;
    text-align: right;
}

.scrollContainer,
.contentContainer {
    padding: 0 $global-spacing;
}

.scrollWrapper:last-child {
    // Add some padding to the end of the scroll container
    .scrollContent:after {
        content: '';
        height: var(--bottom-sheet-padding-bottom);
        display: block;
    }
}

.contentContainer:last-child {
    padding-bottom: var(--bottom-sheet-padding-bottom);
}
