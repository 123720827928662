@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/generated/assets/assets';
@import 'Icons/view/generated/icons';
@import 'Application/view/framework/common/mixins/c24Sprite';

.appInstallTeaser {
    &,
    &:hover {
        color: $silverStar;
    }
}

.appPromotion {
    display: flex;
    flex: 1;
}

.mockupContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2;
}

.mockupImage {
    height: 80px;
}

.appInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 5;
    position: relative;
}

.appBadges {
    margin-top: 10px;
}

.headline {
    font-size: 16px;
    color: $silverStar;
    white-space: nowrap;
}

$starsWidth: 140px;
$starsHeight: 22px;
$scaleRatio: 0.5;
$starsValueOffset: 5px;

.rating {
    margin-top: -3px;
    line-height: 18px;
    font-size: 10px;
    white-space: nowrap;
}

.ratingStarsContainer {
    width: $starsWidth * $scaleRatio;
    height: $starsHeight * $scaleRatio;
    display: inline-block;
    vertical-align: text-bottom;
}

.ratingStarsScaleContainer {
    transform: scale($scaleRatio);
    transform-origin: left top;
    width: $starsWidth;
    height: $starsHeight;
}

.ratingStars {
    @include c24Sprite('star');
}

.ratingValue {
    margin-right: $starsValueOffset;
    height: $starsHeight * $scaleRatio;

    // Added 1px to line-height so stars and value of rating are aligned
    line-height: ($starsHeight * $scaleRatio) + 1px;
    display: inline-block;
    vertical-align: middle;
}
