@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/mixins/tabletAppMaxWidth';

.gratificationBarMobile {
    color: $blueStone;
    background: $whaleHelloThere;
    line-height: 16px;
    font-size: 14px;
    padding: 6px 10px;
    column-gap: $global-spacing-small;

    &__gold,
    &__level1,
    &__level2 {
        color: $tooLarsToHandle;
        background: $pancho;
    }

    &__gold-error,
    &__level1-error,
    &__level2-error {
        color: $ersterFussballClubKoeln;
        background: $pancho;
    }

    &__error {
        color: $ersterFussballClubKoeln;
    }

    &__tabletApp {
        @include tabletAppMaxWidth;
    }
}

.gratificationBarContent {
    display: flex;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    line-height: 16px;
}

.icon {
    display: inline-block;
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    align-self: center;
    flex: 0 0 auto;
    margin: -2px 0;

    // To align the icon with text.
    padding-right: $global-spacing-small;
}

.textContainer {
    flex: 1 1 0;
    align-self: center;
}

.text {
    display: block;
    font-size: 14px;
    line-height: 16px;
}

.tooltipContent {
    // Overwrite margin on paragraphs used by API as HTML content.
    & p {
        margin-bottom: 0;
        line-height: 1.3;
    }
}

.tooltipTrigger {
    align-self: center;
    flex: 0 0 auto;
}

.tooltipTriggerIcon {
    font-size: 20px;
    height: 20px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;

    // To align the icon with text.
    margin: -2px 0;
}
