@import 'Application/view/framework/common/settings';
@import 'Icons/view/generated/icons';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/mixins';

.backNavBarMobile {
    display: flex;
    height: 40px;
    padding: 5px 10px;
    font-size: 16px;
    line-height: 1;
    color: $blueFire;
    border-bottom: 1px solid $marktMaedels;
    justify-content: left;
    align-items: center;
}

.icon {
    display: inline-block;
    vertical-align: sub;
    margin-right: 12px;
    font-size: 20px;

    &:before {
        @include iconFont;

        content: $backArrowIcon;
    }
}
