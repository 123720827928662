@import 'Application/view/framework/common/settings';

.wireframeContactInfoHoldingContactPage {
    text-align: center;
    font-size: 13px;
}

.headline {
    font-weight: bold;
    margin-bottom: $global-spacing-small;
}

.content {
    white-space: break-spaces; // Overwrite holding styles.
}
