@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

.contactBox {
    width: 100%;

    &__mobile {
        .content {
            margin-left: 0;
        }

        .contactItems {
            flex-direction: column;
        }

        .text {
            font-size: 14px;
        }

        .phone {
            margin-bottom: $global-spacing;
        }

        .phoneNumber,
        .emailAddress {
            font-size: 16px;
        }
    }

    &__centered {
        text-align: center;

        & > & {
            text-align: left;
        }

        .contactItems {
            justify-content: center;
        }
    }
}

.text {
    line-height: 20px;
    margin-bottom: $global-spacing-small;
    font-size: 12px;
}

.typeInfo {
    font-size: 12px;
    color: $dustyGrey;
}

.content {
    margin-left: $global-spacing-small;
}

.phone,
.email {
    display: inline-block;
    line-height: normal;

    &:before {
        color: $blueFire;
    }
}

.phoneNumber {
    color: $blueFire;
    font-size: 14px;
}

.emailAddress {
    font-size: 14px;

    &:hover {
        color: $juna;
        text-decoration: underline;
    }
}

.contactItems {
    display: flex;
    column-gap: $global-spacing-large;
}
