@import 'Application/view/framework/common/settings';

.button {
    margin-right: 10px;
    cursor: pointer;
    max-height: 100%;

    &:last-child {
        margin-right: 0;
    }

    &__mobile {
        margin-right: 0;

        > img {
            max-height: 100%;
        }
    }
}
