@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/textOverflowEllipsis';
@import 'Application/view/generated/zIndices';
@import 'Application/view/framework/common/form/floating-labeled-inputs/FloatingLabelInputVariables';

$arrowColor: $dustyGrey;

// Note: We're using a body selector to get a higher priority of the base styles
//       This is needed because the react-select is styled using emotion and these styles
//       will be embedded AFTER our CSS (and therefore having a higher priority)
body {
    .selectInput {
        position: relative;
        padding: 0;
        margin: 0;
        font-size: $inputFontSize;

        &__withoutTransitions > div,
        &__withoutTransitions > label {
            transition: none !important; // Avoid transitions on page load
        }

        // This is our styling though underscores mean modifiers. Now look below
        &__hasFullWidthOptions {
            // They use underscores for elements like in BEM
            .select {
                &__menu {
                    width: auto;
                }

                &__option {
                    white-space: nowrap;
                }
            }
        }

        &__isValueInvalid {
            .select {
                &__control {
                    border-color: $borderColorError;
                }

                &:hover {
                    border-color: $borderColorHover;
                }
            }
        }

        &__paddingForMultiLineOption {
            .select {
                &__option {
                    height: unset;
                    padding: $global-spacing-small $global-spacing;
                }
            }
        }
    }

    .select {
        // Underscores are elements from here

        &__single-value {
            height: auto;
            width: auto;
            padding: 0;
            margin: 0;
            position: inherit;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transform: none;
            color: $silverStar;
        }

        &__control {
            position: relative;
            border: $borderSize solid $borderColor;
            border-radius: $borderRadius;
            height: 50px;
            transition: border-color 0.25s ease-in-out;
            box-shadow: none;

            &:hover {
                border-color: $borderColorHover;
                cursor: pointer;

                :local .arrow {
                    color: $arrowColor;
                }
            }

            &--menu-is-open {
                border-color: $borderColorFocus !important; // override state pseudo-classes
                transition: none;

                :local .arrow {
                    color: $arrowColor !important; // override state pseudo-classes
                    transform: rotate(180deg);
                }
            }
        }

        &__menu {
            margin-top: 0; // overrides standard spacing
            box-shadow: 0 1px 10px 0 rgba($darkSideOfTheForce, 0.16);
            border-radius: $borderRadius;

            // fix rendering behavior (white angles at corners)
            overflow: hidden;
            border: none;
            z-index: zIndex(partialOverlays);
        }

        &__menu-list {
            // prevent horizontal scrollbar in firefox to appear
            overflow-x: hidden;
        }

        &__menu-portal {
            font-size: $inputFontSize;
            z-index: zIndex(tooltipsOverlay);
        }

        &__option {
            display: flex;
            align-items: center;
            padding: 0 $global-spacing;

            // Aware that this value is used in FloatingLabeledSelect to determine menu height.
            height: 39px;

            &--is-selected:not(.select__option--is-disabled) {
                background-color: $almostWhite;
                color: $silverStar;
            }

            &:hover:not(.select__option--is-disabled),
            &--is-focused:not(.select__option--is-disabled) {
                background-color: $frosteon;
                color: $silverStar;
            }
        }

        &__value-container,
        &__input > input {
            height: auto;
            width: auto;
            padding: 15px 0 0 $global-spacing;
            margin: 0;
            position: inherit;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            line-height: inherit;
            font-size: inherit;
        }

        &__input {
            // This is needed to override the display: inline-block.
            // With inline-block the height is not equal to the other input components.
            // It's only necessary for this select component.
            display: block !important;
        }

        &__value-container {
            @include textOverflowEllipsis;

            padding-right: 0;
        }

        &__mobile {
            .inputArrow {
                color: $dustyGrey;
                font-size: 15px;
            }
        }

        &__input-container {
            // Is necessary when select is searchable to center the value vertically
            margin: 0;
            padding: 0;
            height: 37px;
        }
    }

    .inputArrow {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: $global-spacing;
        font-size: 24px;
        line-height: 0;
    }

    .arrow {
        width: 14px;
        margin-right: $global-spacing;
        display: flex;
        justify-content: center;
        font-size: 14px;
        color: $arrowColor;

        &__increaseIconSize {
            font-size: 36px;
        }
    }
}
