@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Application/view/framework/common/functions';
@import 'Application/view/framework/common/mixins/mixins';

$iconFontSize: 18px;

.radioSquareButton {
    background-color: $sophieWhite;
    border: 1px solid $marktMaedels;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    line-height: 1.8;
    align-items: center;

    .icon {
        color: $marktMaedels;
        font-size: $iconFontSize;
        line-height: $iconFontSize;
        margin-right: $global-spacing;
        display: flex;
    }

    &__mobile {
        font-size: 14px;
        line-height: $iconFontSize;

        .icon {
            margin-right: $global-spacing-smedium;
            align-self: flex-start;
            color: $plainGrey;
        }

        &.radioSquareButton__withPaddingBoxAlignedOnTop,
        &.radioSquareButton__withRadioCheckAlignedOnTop {
            padding-left: $iconFontSize + $global-spacing-smedium;
            padding-right: 0;

            .icon {
                left: 0;
            }
        }

        &.radioSquareButton__withVerticalPadding {
            padding-top: $global-spacing;
            padding-bottom: $global-spacing;
        }
    }

    &__isHoverable {
        &.radioSquareButton__showHover:hover {
            border-color: $scorpion;

            .icon {
                color: $silverStar;
            }
        }
    }

    &__isActive {
        border-color: $blueFire;
        background-color: $sophieWhite;
        color: $silverStar;

        &.radioSquareButton__mobile {
            color: $silverStar;
        }

        &.radioSquareButton__isDisabled:before {
            color: $dustyGrey;
        }

        .icon {
            color: $blueFire;
        }
    }

    &__hasError {
        border-color: $ersterFussballClubKoeln;

        &.radioSquareButton__showHover:hover {
            border-color: $ersterFussballClubKoeln;
        }

        &.radioSquareButton__isActive {
            background-color: $sophieWhite;
            border-color: $ersterFussballClubKoeln;
            color: $silverStar;

            .icon {
                color: $ersterFussballClubKoeln;
            }

            &.radioSquareButton__showHover:hover {
                background-color: fadedColor($ersterFussballClubKoeln, 25%);
            }
        }
    }

    &__isDisabled {
        cursor: not-allowed;
        color: $dustyGrey;
    }

    &__withVerticalPadding {
        padding-top: $global-spacing-smedium;
        padding-bottom: $global-spacing-smedium;
    }

    &__smallHorizontalPadding {
        padding-left: $global-spacing;
        padding-right: $global-spacing;
    }

    &__bigHorizontalPadding {
        padding-left: $global-spacing-medium;
        padding-right: $global-spacing-medium;
    }

    /* Radio checkbox should not be aligned to the content */
    &__withRadioCheckAlignedOnTop {
        min-height: 72px;
        height: 100%;
        padding-left: 59px;
        align-items: start;

        .icon {
            position: absolute;
            top: 25px;
            left: $global-spacing-medium;
            margin-right: 0;
        }
    }

    /* Radio checkbox is aligned with the padding box */
    &__withPaddingBoxAlignedOnTop {
        height: 100%;
        padding-left: 59px;
        align-items: start;

        .icon {
            position: absolute;
            top: 12px;
            left: $global-spacing-medium;
            margin-right: 0;
        }
    }

    &__withRadioCheckTopPadding {
        .icon {
            padding-top: 5px;
        }
    }

    &__fullHeight {
        height: 100%;
    }

    &__withTopBorder {
        border: none;
        border-top: 1px solid $marktMaedels;
        border-radius: 0;
    }

    &__withoutBorder {
        border: none;
    }

    &__withoutIcon {
        padding-left: $global-spacing;
    }

    &__withTransparentBackground {
        background-color: transparent;
    }
}
