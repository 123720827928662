// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$c24-sprite-ekomi-stars-grey-name: 'ekomi_starsGrey';
$c24-sprite-ekomi-stars-grey-webp: '/assets/images/sprites/1741600581526/c24-sprite.webp';
$c24-sprite-ekomi-stars-grey-x: 0px;
$c24-sprite-ekomi-stars-grey-y: 0px;
$c24-sprite-ekomi-stars-grey-offset-x: 0px;
$c24-sprite-ekomi-stars-grey-offset-y: 0px;
$c24-sprite-ekomi-stars-grey-width: 294px;
$c24-sprite-ekomi-stars-grey-height: 49px;
$c24-sprite-ekomi-stars-grey-total-width: 294px;
$c24-sprite-ekomi-stars-grey-total-height: 186px;
$c24-sprite-ekomi-stars-grey-image: '/assets/images/sprites/1741600581526/c24-sprite.png';
$c24-sprite-ekomi-stars-grey: ($c24-sprite-ekomi-stars-grey-x, $c24-sprite-ekomi-stars-grey-y, $c24-sprite-ekomi-stars-grey-offset-x, $c24-sprite-ekomi-stars-grey-offset-y, $c24-sprite-ekomi-stars-grey-width, $c24-sprite-ekomi-stars-grey-height, $c24-sprite-ekomi-stars-grey-total-width, $c24-sprite-ekomi-stars-grey-total-height, $c24-sprite-ekomi-stars-grey-image, $c24-sprite-ekomi-stars-grey-name, $c24-sprite-ekomi-stars-grey-webp, );
$c24-sprite-ekomi-stars-name: 'ekomi_stars';
$c24-sprite-ekomi-stars-webp: '/assets/images/sprites/1741600581526/c24-sprite.webp';
$c24-sprite-ekomi-stars-x: 0px;
$c24-sprite-ekomi-stars-y: 53px;
$c24-sprite-ekomi-stars-offset-x: 0px;
$c24-sprite-ekomi-stars-offset-y: -53px;
$c24-sprite-ekomi-stars-width: 294px;
$c24-sprite-ekomi-stars-height: 49px;
$c24-sprite-ekomi-stars-total-width: 294px;
$c24-sprite-ekomi-stars-total-height: 186px;
$c24-sprite-ekomi-stars-image: '/assets/images/sprites/1741600581526/c24-sprite.png';
$c24-sprite-ekomi-stars: ($c24-sprite-ekomi-stars-x, $c24-sprite-ekomi-stars-y, $c24-sprite-ekomi-stars-offset-x, $c24-sprite-ekomi-stars-offset-y, $c24-sprite-ekomi-stars-width, $c24-sprite-ekomi-stars-height, $c24-sprite-ekomi-stars-total-width, $c24-sprite-ekomi-stars-total-height, $c24-sprite-ekomi-stars-image, $c24-sprite-ekomi-stars-name, $c24-sprite-ekomi-stars-webp, );
$c24-sprite-ekomi-es-name: 'ekomi_es';
$c24-sprite-ekomi-es-webp: '/assets/images/sprites/1741600581526/c24-sprite.webp';
$c24-sprite-ekomi-es-x: 0px;
$c24-sprite-ekomi-es-y: 106px;
$c24-sprite-ekomi-es-offset-x: 0px;
$c24-sprite-ekomi-es-offset-y: -106px;
$c24-sprite-ekomi-es-width: 80px;
$c24-sprite-ekomi-es-height: 80px;
$c24-sprite-ekomi-es-total-width: 294px;
$c24-sprite-ekomi-es-total-height: 186px;
$c24-sprite-ekomi-es-image: '/assets/images/sprites/1741600581526/c24-sprite.png';
$c24-sprite-ekomi-es: ($c24-sprite-ekomi-es-x, $c24-sprite-ekomi-es-y, $c24-sprite-ekomi-es-offset-x, $c24-sprite-ekomi-es-offset-y, $c24-sprite-ekomi-es-width, $c24-sprite-ekomi-es-height, $c24-sprite-ekomi-es-total-width, $c24-sprite-ekomi-es-total-height, $c24-sprite-ekomi-es-image, $c24-sprite-ekomi-es-name, $c24-sprite-ekomi-es-webp, );
$c24-sprite-ekomi-name: 'ekomi';
$c24-sprite-ekomi-webp: '/assets/images/sprites/1741600581526/c24-sprite.webp';
$c24-sprite-ekomi-x: 84px;
$c24-sprite-ekomi-y: 106px;
$c24-sprite-ekomi-offset-x: -84px;
$c24-sprite-ekomi-offset-y: -106px;
$c24-sprite-ekomi-width: 80px;
$c24-sprite-ekomi-height: 80px;
$c24-sprite-ekomi-total-width: 294px;
$c24-sprite-ekomi-total-height: 186px;
$c24-sprite-ekomi-image: '/assets/images/sprites/1741600581526/c24-sprite.png';
$c24-sprite-ekomi: ($c24-sprite-ekomi-x, $c24-sprite-ekomi-y, $c24-sprite-ekomi-offset-x, $c24-sprite-ekomi-offset-y, $c24-sprite-ekomi-width, $c24-sprite-ekomi-height, $c24-sprite-ekomi-total-width, $c24-sprite-ekomi-total-height, $c24-sprite-ekomi-image, $c24-sprite-ekomi-name, $c24-sprite-ekomi-webp, );
$c24-sprite-width: 294px;
$c24-sprite-height: 186px;
$c24-sprite-image: '/assets/images/sprites/1741600581526/c24-sprite.png';
$c24-sprite-sprites: ($c24-sprite-ekomi-stars-grey, $c24-sprite-ekomi-stars, $c24-sprite-ekomi-es, $c24-sprite-ekomi, );
$c24-sprite: ($c24-sprite-width, $c24-sprite-height, $c24-sprite-image, $c24-sprite-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include c24-sprite-sprite-width($icon-home);
// }
//
// .icon-email {
//   @include c24-sprite-sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin c24-sprite-sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin c24-sprite-sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin c24-sprite-sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin c24-sprite-sprite-image($sprite) {
    $sprite-image: nth($sprite, 9);
    $sprite-image-webp: nth($sprite, 11);
    background-image: url(#{$sprite-image});
    :global .webp & {
        background-image: url(#{$sprite-image-webp});
    }
}

@mixin c24-sprite-sprite($sprite) {
  @include c24-sprite-sprite-image($sprite);
  @include c24-sprite-sprite-position($sprite);
  @include c24-sprite-sprite-width($sprite);
  @include c24-sprite-sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include c24-sprite-sprites($spritesheet-sprites);
@mixin c24-sprite-sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include c24-sprite-sprite($sprite);
    }
  }
}
