/**
* We can avoid displaying stuff where controls or notches on mobile devices are.
* The variables are defined in wireframe-resets.
*/
@mixin safeAreaInset($insets...) {
    @each $inset in $insets {
        @if $inset == bottom or $inset == padding-bottom {
            #{$inset}: var(--safe-area-inset-bottom);
        } @else if $inset == top or $inset == padding-top {
            #{$inset}: var(--safe-area-inset-top);
        } @else if $inset == left or $inset == padding-left {
            #{$inset}: var(--safe-area-inset-left);
        } @else if $inset == right or $inset == padding-right {
            #{$inset}: var(--safe-area-inset-right);
        }
    }
}
