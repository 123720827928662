@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/mixins/textOverflowEllipsis';

.placeholder {
    @include textOverflowEllipsis;

    position: absolute;
    z-index: 1;
    top: 22px;
    left: $global-spacing;
    right: $global-spacing;
    color: $dustyGrey;
    opacity: 0;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
    border: 1px solid transparent;

    &__isVisible {
        opacity: 1;
    }

    &__slim {
        &.placeholder__mobile {
            font-size: 16px;
            top: 7px;
            left: $global-spacing-small;
            right: $global-spacing-small;
        }

        &.placeholder__desktop,
        &.placeholder__tablet {
            top: 8px;
            left: $global-spacing-small;
            right: $global-spacing-small;
        }
    }
}
