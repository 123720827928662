@import 'Application/view/framework/common/tooltip/variables';
@import 'Application/view/generated/colors';
@import 'Application/view/generated/zIndices';

.arrowContainer {
    position: relative;
    z-index: zIndex(tooltipsOverlay) + 1;
    display: flex;
    overflow: hidden;

    // Overlap the arrowed containers border in each direction
    &__top {
        top: 1px;
        align-items: flex-end;
        justify-content: center;

        .arrow {
            transform: translateY(50%) rotate(45deg);
        }
    }

    &__right {
        right: 1px;
        align-items: center;
        justify-content: flex-start;

        .arrow {
            transform: translateX(-50%) rotate(45deg);
        }
    }

    &__bottom {
        bottom: 1px;
        align-items: flex-start;
        justify-content: center;

        .arrow {
            transform: translateY(-50%) rotate(45deg);
        }
    }

    &__left {
        left: 1px;
        align-items: center;
        justify-content: flex-end;

        .arrow {
            transform: translateX(50%) rotate(45deg);
        }
    }

    &__darkBlue {
        .arrow {
            background-color: rgba($effiEffiSteffi, 0.9);
            border-color: rgba($effiEffiSteffi, 0);
        }
    }

    &__white {
        &.arrowContainer__mobile {
            .arrow {
                border-color: $blueFire;
                box-shadow: 0 1px 8px rgba($darkSideOfTheForce, 0.1);
            }
        }
    }

    &__error {
        .arrow {
            border-color: $borderColorError;
        }
    }
}

.arrow {
    background-color: $backgroundDefault;
    border: 1px solid $backgroundDefault;
    box-shadow: $boxShadow;
}
