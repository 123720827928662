@import 'Application/view/framework/common/mixins/safeAreaInset';
@import 'Application/view/generated/colors';

@keyframes bottomSheetSlideIn {
    0% {
        transform: translateY(100vh);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes bottomSheetSlideOut {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(100vh);
    }
}

.bottomSheetContainer {
    position: relative;
    will-change: transform;
    transform: translateY(100vh);

    &__slid-in,
    &__slid-out {
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        animation-direction: normal;
    }

    &__slid-in {
        animation-name: bottomSheetSlideIn;
    }

    &__slid-out {
        animation-name: bottomSheetSlideOut;
    }
}

.bottomSheet {
    background-color: $sophieWhite;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 100vw;

    &__withSafeInset {
        @include safeAreaInset('padding-top', 'padding-bottom', 'padding-left', 'padding-right');
    }
}
