@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';
@import 'Icons/view/generated/icons';
@import 'Application/view/framework/common/mixins/mixins';

$borderSize: 1px;
$checkboxSizeDesktop: 18px;
$lineHeightDesktop: 20px;
$fontSizeDesktop: 12px;
$checkboxSizeMobile: 22px;
$lineHeightMobile: 22px;
$fontSizeMobile: 15px;
$increasedCheckboxSize: 25px;

.checkboxInput {
    position: relative;
    display: inline-flex;

    &__withMargin {
        margin-bottom: $global-spacing-small;
    }

    &__asBlock {
        display: flex;
    }

    &__shouldBeAligned {
        align-items: center;
    }

    &__default {
        font-size: 14px;
    }

    &__medium {
        font-size: 16px;
    }

    &__big {
        font-size: 18px;
    }

    &__desktop {
        padding-left: $checkboxSizeDesktop + $global-spacing-small;
        line-height: $lineHeightDesktop;
        cursor: pointer;

        &:not(.checkboxInput__tablet, .checkboxInput__disabled):hover {
            .icon {
                border-color: $blueFire;
            }
        }

        &.checkboxInput__disabled {
            color: $plainGrey;
            cursor: default;
        }
    }

    &__mobile {
        padding-left: $checkboxSizeMobile + $global-spacing-smedium;
        line-height: $lineHeightMobile;

        &.checkboxInput__disabled {
            // Override default foundation label color.
            color: $plainGrey !important;
        }

        &.checkboxInput__lessLineHeight {
            line-height: 1.2;
        }
    }

    &__biggerCheckbox {
        padding-left: $increasedCheckboxSize + $global-spacing-smedium;
        line-height: $increasedCheckboxSize;

        .icon {
            width: $increasedCheckboxSize;
            height: $increasedCheckboxSize;
        }

        &.checkboxInput__withMargin {
            margin-bottom: $global-spacing-smedium;
        }
    }
}

.icon {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $sophieWhite;
    color: $plainGrey;
    border: $borderSize solid $plainGrey;
    border-radius: $global-radius;

    &__isChecked {
        color: $sophieWhite;
        border: none;
        background-color: $blueFire;
    }

    &__hasError {
        border: $borderSize solid $ersterFussballClubKoeln;

        &.icon__isChecked {
            background-color: $ersterFussballClubKoeln;
        }
    }

    &__greyTheme {
        &.icon__isChecked {
            background-color: $tundora;
        }
    }

    &__desktop {
        font-size: $fontSizeDesktop;
        width: $checkboxSizeDesktop;
        height: $checkboxSizeDesktop;

        &.icon__hasError {
            line-height: $lineHeightDesktop - 2 * $borderSize;
        }
    }

    &__mobile {
        font-size: $fontSizeMobile;
        width: $checkboxSizeMobile;
        height: $checkboxSizeMobile;
        border-radius: $global-radius;
        color: $sophieWhite;

        &.icon__disabled {
            background-color: $almostWhite;
        }

        &.icon__hasError {
            line-height: $lineHeightMobile - 2 * $borderSize;
        }
    }
}

.control {
    position: absolute;
    visibility: hidden;
    z-index: -1;
}
