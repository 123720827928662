@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.wireframeChatbotInfo {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__mobile {
        .headline {
            font-size: 18px;
            font-weight: bold;
        }

        .textContent {
            width: auto;
            text-align: left;
        }
    }
}

.avatarContent {
    font-size: 20px;
    line-height: 23px;
    width: 55px;
    height: 55px;
    margin-top: -13px;
    transform: translate(0, 50%);
}

.avatar {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 2px solid $sophieWhite;
    object-fit: cover;
}

.headline {
    font-size: 14px;
    color: $silverStar;
    font-weight: bold;
    text-align: center;
}

.textContent {
    font-size: 13px;
    line-height: 16px;
    color: $silverStar;
    width: 281px;
    height: auto;
    border-radius: 17px;
    background-color: $frosteon;
    padding: 15px;
    white-space: normal;
}

.buttonContainer {
    margin-top: $global-spacing-small;
    display: flex;
    justify-content: flex-end;
}

.text {
    margin-top: 20px;
    margin-bottom: 5px;
}

.textLink {
    font-size: 13px;
    color: $blueFire;
}

.spinner {
    display: flex;
    justify-content: center;
}
