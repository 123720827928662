@import 'Application/view/generated/colors';
@import 'Application/view/generated/zIndices';
@import 'Application/view/framework/common/settings';

@mixin small-phone {
    @media screen and (width <= 360px) {
        @content;
    }

    @media screen and (height <= 700px) {
        @content;
    }
}

:global(.splash-screen-closed #splashScreenContainer) {
    display: none;
}

.splashScreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: scroll;
    z-index: zIndex(portalContainer);
    background-color: $splashBlue;
}

.background {
    position: relative;
    width: 100%;
    min-width: 320px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto 750px;
    background-color: $splashBlue;
    display: flex;
    justify-content: center;

    &__fullSize {
        background-size: 100% 100%;
    }

    &__gradientBackground {
        background: linear-gradient(135deg, $buckIsBluetiful 0%, $splashBlue 100%);
    }

    @include small-phone {
        background-size: auto 100%;

        &__fullSize {
            background-size: 100% 100%;
        }
    }

    @include breakpoint(medium up) {
        min-height: 800px;
    }
}

.imageContainer {
    position: relative;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 240px;

    @include small-phone {
        transform: translateX(-50%) scale(0.9);
    }

    @media screen and (height <= 650px) {
        top: 10px;
        transform: translateX(-50%) scale(0.8);
    }

    @media screen and (height <= 600px) {
        top: 0;
        transform: translateX(-50%) scale(0.7);
    }
}

.award {
    position: absolute;
    width: 85px;
    right: 0;
    bottom: 40px;
}

.close {
    color: $sophieWhite;
    position: absolute;
    padding: 20px 20px 80px 80px;
    right: 0;
    top: 0;
    line-height: 1;
    font-size: 28px;
    z-index: zIndex(contentElements);
}

.contents {
    min-width: 320px;
    position: relative;

    @include breakpoint(medium up) {
        min-height: 800px;
    }

    &__de,
    &__at {
        .plectrumContainer {
            top: 60px;
            left: 20px;

            @include small-phone {
                top: 6%;
                left: 8%;
            }
        }

        .promptContainer {
            top: 420px;

            @include small-phone {
                top: 55%;
            }
        }
    }

    &__expand {
        width: 100%;
        margin: 0 $global-spacing-large;
    }
}

.bonusContainer {
    position: absolute;
    top: 20px;
    left: 20px;
}

.plectrumContainer {
    position: absolute;
    top: 20px;
    left: 0;

    @include small-phone {
        top: 3%;
        left: 6%;
        transform: scale(0.75);
    }
}

.promptContainer {
    position: absolute;
    top: 380px;
    width: 100%;
    text-align: center;
    color: $sophieWhite;
    line-height: 1;
    padding: 0 $global-spacing-xsmall;

    @include small-phone {
        top: 50%;
        transform: scale(0.85);
    }
}

.prompt {
    font-size: 32px;
}

.button {
    margin-top: $global-spacing;
}

.appButtons {
    margin-top: $global-spacing-small;
    display: flex;
    justify-content: center;
}
