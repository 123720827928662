@import 'Application/view/framework/common/settings';

.appStoreButtons {
    &__mobile {
        width: 100%;
        display: flex;
        justify-content: center;
        max-height: 100%;
        column-gap: $global-spacing-small;

        &.appStoreButtons__leftAligned {
            width: 60%;
        }
    }
}
