@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/assets/assets';
@import 'Application/view/generated/colors';
@import 'Application/view/generated/zIndices';
@import 'Application/view/framework/common/mixins/safeAreaInset';

$componentClass: popUpFrame;
$wireframeHeaderHeight: 74px;
$wireframeNavigationHeight: 42px;
$titleHeight: 40px !default;

.#{$componentClass} {
    @include safeAreaInset(top, right, bottom, left);

    position: fixed;
    background: rgba($darkSideOfTheForce, 0.5);
    z-index: zIndex(wireframeOverlays);

    // Assure the default left alignment for the text
    text-align: left;
    white-space: normal;
    display: flex;
    flex-flow: row;
    align-items: center;

    // Prevents the popup from inheriting the cursor of the calling element.
    cursor: default;

    &__desktop {
        justify-content: center;

        .container {
            position: relative;
            max-height: 90%;
            width: 100%;
            max-width: $global-width + 18px;
            background: $body-background;
            border: 1px solid rgba($doveGrey, 0.3);
            display: flex;
            flex-direction: column;
            border-radius: $global-radius;
            overflow-y: auto;

            // Note: Explicit prefixing because this is not a standard property, see: https://github.com/postcss/autoprefixer/issues/769
            -webkit-overflow-scrolling: touch;
            box-shadow: 0 0 8px 0 rgba($doveGrey, 0.45);
        }

        &.#{$componentClass} {
            &__defaultNoWidth {
                .container {
                    width: auto;
                }
            }

            &__hasCloseIconAbovePopUp {
                .container {
                    background-color: unset;

                    // Just providing some room for the close icon
                    padding-top: 35px;
                    margin-top: -35px;
                    border-radius: 0;
                    overflow: hidden;
                    box-shadow: none;
                }

                .content {
                    background-color: $body-background;
                    border-radius: $global-radius;
                    height: auto;
                    padding: 0;
                    overflow: hidden;
                    box-shadow: 0 0 8px 0 rgba($doveGrey, 0.45);
                }

                .close {
                    overflow: hidden;
                }

                .closeIconContainer {
                    height: 30px;
                    width: 30px;
                    font-size: 40px;
                    color: $sophieWhite;
                }

                .closeIcon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &__deletion {
                .container {
                    width: auto;
                    min-width: 300px;
                    max-width: 350px;
                    border: none;
                }

                .content {
                    min-height: 150px;
                    padding: 20px;
                    border-radius: 5px;
                }

                .closeIconContainer {
                    font-size: 35px;
                }
            }

            &__checkBooking {
                .container {
                    width: 853px;
                }

                .close {
                    &:hover {
                        color: $dustyGrey;
                    }
                }

                /* This style is required to style the booking addons table sent by the API. It has to be done this way, because
                 * our API devs can't add any attributes to the sent elements. This will be replaced as soon as the API sends us something
                 * different than HTML */
                & .bookingDetailsPopUp table {
                    & tr {
                        & > th {
                            text-align: left;
                        }

                        & > td {
                            padding-right: $global-spacing;

                            &:last-child {
                                text-align: right;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }

            &__hotlineContact {
                top: $wireframeHeaderHeight;

                .container {
                    width: 620px;
                    top: -#{$wireframeHeaderHeight};
                }
            }

            &__thirdViewStandalone {
                .container {
                    width: 70%;
                    max-width: 800px;
                    max-height: calc(100vh - 60px);
                }
            }

            &__thirdViewStandaloneSmall {
                .container {
                    width: 300px;
                }
            }
        }
    }

    &__mobile {
        font-size: 16px;

        .container {
            position: relative;
            width: 100%;
            max-height: 85%;
            margin: 0 $global-spacing;
            line-height: 1.3;

            // fix centering for iOS Browsers without modifying things like TrustYou overlay
            -webkit-overflow-scrolling: touch;
            display: flex;
            flex-direction: column;

            &__hasCloseIconAbovePopUp {
                margin-top: 30px;
            }
        }

        .title {
            padding: $global-spacing 46px $global-spacing $global-spacing;
            width: 100%;
            background: $sophieWhite;
            margin: 0;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            line-height: 21px;
            font-size: 16px;
            position: relative;

            // Due to dynamic heights and rounding errors, different browsers showed unwanted pseudo lines between
            // the title container and the content container. This little hack just paints the line over or in other
            // words fills the gap. Yea, i know. Freaking genius!
            &:after {
                content: '';
                background: inherit;
                position: absolute;
                bottom: -2px;
                left: 0;
                height: 4px;
                width: 100%;
            }

            + .content {
                // remove borderRadius if title set

                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        .content {
            height: auto;
            padding: 0;
            overflow-x: hidden;
            background-color: $sophieWhite;
            border-radius: 10px;

            &__mobileWithoutHeader {
                border-radius: 10px;
                font-size: 16px;
                line-height: 1.3;
                padding-top: $global-spacing;
                max-height: calc(80vh - 30px);
            }
        }

        .close {
            color: $sophieWhite;
            width: fit-content;
            font-size: 35px;
            line-height: 1;
            top: -30px;

            &__asTextButton {
                color: $sophieWhite;
                margin-bottom: $global-spacing;
                font-size: 20px;
            }

            // We use this container to measure and position an X icon that is exactly 20x20 px big
            .closeIconContainer {
                position: relative;
                width: 20px;
                height: 20px;
            }

            .closeIcon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.#{$componentClass}__deletion {
            padding: 0;

            .container {
                width: 100%;
                height: 100%;
                max-height: 100%;
                left: 0;
                border: none;
                background: $almostWhite;
                padding: $global-spacing;
            }
        }

        &.#{$componentClass}__thirdViewStandalone,
        &.#{$componentClass}__thirdViewStandaloneExtendedWidth {
            .container {
                width: 100%;
                height: 100%;
                max-height: unset;
                border: none;
                margin: 0;
                background: $sophieWhite;

                // This overlay provides its own header so the padding is set there as well
                padding-top: 0;
            }

            .content {
                padding: 0;
            }
        }
    }

    &__tablet {
        height: 100%;
    }

    &__app {
        &.#{$componentClass}__noWebViewBottomSafeArea {
            bottom: 0;
        }
    }

    &__slideIn {
        $popUpWidth: 645px;

        .container {
            position: absolute;
            top: 0;
            bottom: 0;
            left: auto;
            right: -$popUpWidth;
            width: $popUpWidth;
            max-height: none;
            border: none;

            // Unfortunately we can't animate transform anymore because of some IE bug
            // @see https://bitbucket.check24.de/projects/HG/repos/frontend/pull-requests/4876/overview?commentId=359453
            will-change: right;
            transition: right 0.5s ease-out;
        }

        .titleContainer {
            z-index: zIndex(partialElements) + 1;
            background-color: $effiEffiSteffi;
            display: flex;
            flex: 0 0 $titleHeight;
        }

        .title {
            flex: 1;
            color: $sophieWhite;
            font-size: 18px;
            display: flex;
            align-items: center;
        }

        .close {
            position: static;
            top: auto;
            right: auto;
            width: $titleHeight;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            line-height: 1;
            color: $sophieWhite;

            &:hover {
                color: $marktMaedels;
            }

            * {
                cursor: pointer;
            }
        }

        .content {
            flex: 1 1 auto;
            overflow: auto;
            width: auto;
            height: auto;
        }

        &.#{$componentClass}__slidIn {
            user-select: none;

            .container {
                right: 0;
                user-select: text;
            }
        }
    }

    &__finishedOpeningWithAnimation {
        .container {
            transform: scale(1);
        }
    }

    &__roundedCorners {
        .container {
            border: none;
            border-radius: 5px;
        }
    }

    &__roundedCornersMobile {
        .container {
            border: none;
            border-radius: 10px;

            &__hasCloseIconAbovePopUp {
                border-top-right-radius: 0;
            }
        }

        .content {
            padding: $global-spacing;
        }

        .title {
            + .content {
                padding-top: 0;
            }
        }
    }

    &__spsIFrameBlockedModal {
        .container {
            width: 775px;
        }
    }

    &__fitWidthToContent {
        .container {
            width: auto;
            max-width: unset;
        }
    }
}

.close {
    cursor: pointer;
    font-size: 16px;
    line-height: 32px;
    font-weight: normal;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    transition: 0.25s color;
    color: $plainGrey;
    z-index: zIndex(partialElements);

    &:hover {
        color: $blueFire;
    }
}

.title {
    margin: 15px 0 15px 15px;
}

.content {
    width: 100%;
    height: 100%;
    padding: $global-spacing;
    z-index: zIndex(contentElements);

    &__noPadding {
        padding: 0;
    }
}
