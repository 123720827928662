@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/zIndices';

.scrollableContent {
    font-size: 15px;
    height: calc(100% - $global-spacing-small);
    margin-bottom: $global-spacing-small;

    &__horizontalPadding-none {
        padding-left: 0;
        padding-right: 0;
    }

    &__horizontalPadding-small {
        padding-left: 10px;
        padding-right: 10px;
    }

    &__horizontalPadding-default {
        padding-left: $global-spacing;
        padding-right: $global-spacing;
    }

    &__verticalPadding-none {
        padding-top: 0;
        padding-bottom: 0;
    }

    &__verticalPadding-small {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &__verticalPadding-default {
        padding-top: $global-spacing;
        padding-bottom: $global-spacing;
    }

    &__withoutMarginBottom {
        margin-bottom: 0;
    }
}

.overflowShadow {
    position: relative;

    &:before {
        content: '';
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        height: 6px;
        pointer-events: none;
        z-index: zIndex(flyingOverContentElements);
    }

    &__visible:before {
        display: block;
    }

    &__scrollableUpwards:before {
        background: linear-gradient(rgba($darkSideOfTheForce, 0.1), rgba($darkSideOfTheForce, 0) 75%);
        top: 100%;
    }

    &__scrollableDownwards:before {
        background: linear-gradient(rgba($darkSideOfTheForce, 0) 25%, rgba($darkSideOfTheForce, 0.1));
        bottom: 100%;
    }
}
